import { Component, Input, OnInit, Signal, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPropiedadesService } from '../../services/shared-propiedades.service';
import { Estatus } from '../../models/propiedad.model';

@Component({
  selector: 'dashboard-estatus-filter',
  standalone: true,
  imports: [NgbDropdownModule, CommonModule],
  templateUrl: './dashboard-estatus-filter.component.html'
})

export class DashboardEstatusFilterComponent implements OnInit {
  @Input({ required: true }) dashboardReq!: Signal<string>;
  @Input() estatusExcluidos: string[] = [];
  public propiedadesService = inject(SharedPropiedadesService);

  public dropdownEstatus: Signal<Estatus[]> = computed<Estatus[]>(() => this.propiedadesService.catalogoEstatus().filter(estatus => !this.estatusExcluidos.includes(estatus.id)));

  public filtrarPorEstatus(estatus: string) {
    console.log("Filtrando por estatus: ", estatus);
    this.propiedadesService.estatus.set(estatus.toLowerCase());
    this.propiedadesService.pagina.set(0);
    this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq())
  }

  ngOnInit () {
    console.log("Los estatus excluidos son: ", this.estatusExcluidos);
  }
}
