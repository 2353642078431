<div class="d-flex justify-content-end breadcrumbs align-items-center small">
  <span>Inicio</span>
  @for (breadcrumb of this.breadcrumbs; track $index) {
    <svg
      xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-content-secondary">
      <path
        d="M9.00002 6.71075C8.61002 7.10075 8.61002 7.73075 9.00002 8.12075L12.88 12.0008L9.00002 15.8808C8.61002 16.2708 8.61002 16.9008 9.00002 17.2908C9.39002 17.6808 10.02 17.6808 10.41 17.2908L15 12.7008C15.39 12.3108 15.39 11.6808 15 11.2908L10.41 6.70075C10.03 6.32075 9.39002 6.32075 9.00002 6.71075Z"
        fill="currentColor" />
    </svg>
    <span>{{ breadcrumb }}</span>
  }
</div>
