<div ngbDropdown container="body" class="d-inline-block w-100">
  <button type="button"
    class="btn btn-outline-stroke-primary text-content-secondary rounded-3 d-flex align-items-center gap-2 w-100"
    id="dropdownEstados" ngbDropdownToggle>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M9.99993 1.63583C8.26791 1.63583 6.60682 2.32387 5.38209 3.5486C4.15737 4.77333 3.46932 6.43441 3.46932 8.16644C3.46932 11.1343 4.76794 13.467 6.26874 15.1774C7.70105 16.8098 9.28806 17.8393 9.99958 18.256C10.7109 17.8398 12.2982 16.8107 13.7308 15.1783C15.2317 13.468 16.5305 11.1352 16.5305 8.16644C16.5305 6.43441 15.8425 4.77333 14.6178 3.5486C13.393 2.32387 11.732 1.63583 9.99993 1.63583ZM9.8984 18.3145C9.89823 18.3146 9.89807 18.3147 9.8979 18.3147L9.89801 18.3147C9.89814 18.3146 9.89827 18.3145 9.8984 18.3145ZM4.22763 2.39414C5.75854 0.863229 7.8349 0.00317383 9.99993 0.00317383C12.165 0.00317383 14.2413 0.86323 15.7722 2.39414C17.3031 3.92505 18.1632 6.00141 18.1632 8.16644C18.1632 11.6467 16.6326 14.3469 14.9579 16.2552C13.2877 18.1583 11.442 19.3131 10.7077 19.7324C10.492 19.8556 10.2479 19.9204 9.99953 19.9204C9.75115 19.9204 9.50706 19.8556 9.29137 19.7324L9.29116 19.7323C8.55707 19.3127 6.71157 18.1575 5.04154 16.2542C3.36704 14.3459 1.83667 11.6459 1.83667 8.16644C1.83667 6.00141 2.69673 3.92505 4.22763 2.39414ZM6.82517 4.99167C7.66717 4.14967 8.80917 3.67664 9.99993 3.67664C11.1907 3.67664 12.3327 4.14967 13.1747 4.99167C14.0167 5.83367 14.4897 6.97567 14.4897 8.16644C14.4897 9.35721 14.0167 10.4992 13.1747 11.3412C12.3327 12.1832 11.1907 12.6562 9.99993 12.6562C8.80917 12.6562 7.66717 12.1832 6.82517 11.3412C5.98317 10.4992 5.51014 9.35721 5.51014 8.16644C5.51014 6.97567 5.98317 5.83367 6.82517 4.99167ZM9.99993 5.3093C9.24217 5.3093 8.51545 5.61032 7.97963 6.14613C7.44381 6.68195 7.14279 7.40868 7.14279 8.16644C7.14279 8.9242 7.44381 9.65093 7.97963 10.1867C8.51545 10.7226 9.24217 11.0236 9.99993 11.0236C10.7577 11.0236 11.4844 10.7226 12.0202 10.1867C12.5561 9.65093 12.8571 8.9242 12.8571 8.16644C12.8571 7.40868 12.5561 6.68195 12.0202 6.14613C11.4844 5.61032 10.7577 5.3093 9.99993 5.3093Z"
        fill="#596888" />
    </svg>
    {{ (this.propiedadesService.busquedaEstado() | truncate: 7) || "Estado" }}
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownEstados" class="overflow-y-scroll dp-options-container w-100 shadow">
    @for (estado of estados; track $index){
    <button ngbDropdownItem (click)="filtrarPorEstado(estado)">{{estado}}</button>
    }
  </div>
</div>
