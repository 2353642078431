import { Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Propiedad } from '../../models/propiedad.model';
import { TruncatePipe } from '../../pipes/truncate.pipe';

@Component({
  selector: 'shared-dashboard-propiedad',
  standalone: true,
  imports: [
    CommonModule,
    TruncatePipe,
    NgOptimizedImage
  ],
  templateUrl: './dashboard-propiedad.component.html',
  styleUrl: './dashboard-propiedad.component.scss'
})

export class DashboardPropiedadComponent {
  @Input({required: true}) propiedad!: Propiedad;
}
