<form
  class="row g-3 align-items-center"
  (ngSubmit)="onSubmit()"
>
  <div class="col-9">
    <label class="visually-hidden" for="busquedaDashboard">Buscar</label>
    <div class="input-group">
      <div class="input-group-text border border-1 border-stroke-primary border-end-0 rounded-start-3 bg-transparent">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M3.74074 3.74074C5.06917 2.41232 6.87091 1.66602 8.74958 1.66602C10.6283 1.66602 12.43 2.41232 13.7584 3.74074C15.0868 5.06917 15.8332 6.8709 15.8332 8.74958C15.8332 10.3483 15.2927 11.8914 14.3129 13.1344L18.0893 16.9107C18.4147 17.2362 18.4147 17.7638 18.0893 18.0893C17.7638 18.4147 17.2362 18.4147 16.9107 18.0893L13.1344 14.3129C11.8914 15.2927 10.3483 15.8332 8.74958 15.8332C6.87091 15.8332 5.06917 15.0868 3.74074 13.7584C2.41232 12.43 1.66602 10.6283 1.66602 8.74958C1.66602 6.8709 2.41232 5.06917 3.74074 3.74074ZM12.5799 12.5799C13.5958 11.564 14.1665 10.1862 14.1665 8.74958C14.1665 7.31293 13.5958 5.93512 12.5799 4.91926C11.564 3.90339 10.1862 3.33268 8.74958 3.33268C7.31293 3.33268 5.93512 3.90339 4.91926 4.91926C3.90339 5.93512 3.33268 7.31293 3.33268 8.74958C3.33268 10.1862 3.90339 11.564 4.91926 12.5799C5.93512 13.5958 7.31293 14.1665 8.74958 14.1665C10.1862 14.1665 11.564 13.5958 12.5799 12.5799Z"
            fill="#596888" />
        </svg>
      </div>
      <input
        type="text"
        class="form-control border border-1 border-start-0 rounded-end-3 border-stroke-primary bg-transparent"
        id="busquedaDashboard"
        [maxlength]="maxLength"
        placeholder="Búsqueda por texto"
        [formControl]="terminoBusqueda"
      >
    </div>
  </div>

  <div class="col-3 p-0">
    <button
      type="submit"
      class="btn btn-primary text-white rounded-5 w-100 fw-semibold"
      [ngClass]="{'btn-oxxo-red': terminoBusqueda.valid, 'btn-ui-feedback-disabled': !terminoBusqueda.valid}"
      [disabled]="!terminoBusqueda.valid"
    >Buscar</button>
  </div>
</form>
