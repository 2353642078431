import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, inject, Input, OnInit } from '@angular/core';
import { Bitacora } from '../../models/bitacora.model';
import { BrowserStorageService } from '../../services/browser-storage.service';
import { DatosInmuebleService } from '../../services/datos-inmueble.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'shared-bitacora',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './shared-bitacora.component.html',
  styleUrl: './shared-bitacora.component.scss'
})

export class SharedBitacoraComponent implements OnInit {
  private browserStorageService: BrowserStorageService = inject(BrowserStorageService);
  private http: HttpClient = inject(HttpClient);
  private inmuebleService: DatosInmuebleService = inject(DatosInmuebleService);

  @Input({required: true}) apiUrl!: string;
  public bitacora: Bitacora[] = [];
  public isLoading: boolean = false;

  public getBitacora(apiUrl: string) {
    const token = this.browserStorageService.get('token');
    let headers;
    if (token !== null) {
      headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    }
    return this.http.get<Bitacora[]>(`${apiUrl}/bitacoraEstatus/consultar?propiedadId=${this.inmuebleService.propiedadId()}`, { headers: headers, withCredentials: true });
  }

  public ordenarBitacora(event: Event) {
    const target = event.target as HTMLSelectElement;
    const orden = target.value;

    return this.bitacora.sort((a, b) => {
      if (orden === 'ascendente') {
        return a.id - b.id;
      } else {
        return b.id - a.id;
      }
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getBitacora(this.apiUrl).subscribe({
      next: (response: any) => {
        this.bitacora = response;
        this.isLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.bitacora = [];
        this.isLoading = false;
        console.error('Error al obtener la bitácora: ', error.error.message);
        throw new Error('Error al obtener la bitácora: ', error.error.message);
      }
    });
  }

}
