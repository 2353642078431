import { Component, Input, Signal, computed, inject } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPropiedadesService } from '../../services/shared-propiedades.service';

@Component({
  selector: 'dashboard-paginacion',
  standalone: true,
  imports: [NgbDropdownModule],
  templateUrl: './dashboard-paginacion.component.html',
  styleUrl: './dashboard-paginacion.component.css'
})
export class DashboardPaginacionComponent {
  @Input({required: true}) dashboardReq!: Signal<string>;
  public propiedadesService = inject(SharedPropiedadesService);

  public siguientePagina () {
    this.propiedadesService.pagina.update((pagina) => pagina + 1);
    this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq());
  }

  public anteriorPagina () {
    this.propiedadesService.pagina.update((pagina) => pagina - 1);
    this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq());
  }
}
