import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-breadcrumbs',
  standalone: true,
  imports: [],
  templateUrl: './shared-breadcrumbs.component.html',
  styleUrl: './shared-breadcrumbs.component.scss'
})
export class SharedBreadcrumbsComponent {
  @Input() breadcrumbs: string[] = [''];
}
