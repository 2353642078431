<div ngbDropdown class="d-inline-block">
  <button type="button" class="btn" id="dropdownSort" ngbDropdownToggle>
    Ordenar por: {{ this.propiedadesService.filtro() | titlecase }}
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownBSort">
    @for (ordenamiento of this.ordenamientosValidos; track $index) {
    <button ngbDropdownItem (click)="this.ordenarPor(ordenamiento)">
      {{ ordenamiento | titlecase }}
    </button>
    }
  </div>
</div>
