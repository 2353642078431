import { Component, Input, Signal, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, FormControl, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedPropiedadesService } from '../../services/shared-propiedades.service';

@Component({
  selector: 'dashboard-search-box',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './dashboard-search-box.component.html',
  styleUrl: './dashboard-search-box.component.scss'
})
export class DashboardSearchBoxComponent {
  public propiedadesService = inject(SharedPropiedadesService);
  @Input({required: true}) dashboardReq!: Signal<string>;
  @Input({required: true}) maxLength: number = 10;
  public terminoBusqueda = new FormControl('', Validators.required);

  onSubmit() {
    if(this.terminoBusqueda.valid) {
      this.propiedadesService.busqueda.set(this.terminoBusqueda.value ?? '');
    }
    this.propiedadesService.pagina.set(0);
    this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq())
    this.terminoBusqueda.setValue('');
  }
}
