import { Component } from '@angular/core';

@Component({
  selector: 'boton-regresar',
  standalone: true,
  imports: [],
  templateUrl: './boton-regresar.component.html',
  styleUrl: './boton-regresar.component.scss'
})

export class BotonRegresarComponent {

}
