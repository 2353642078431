import { Inject, Injectable, InjectionToken } from '@angular/core';

export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
  providedIn: 'root',
  factory: () => localStorage
});

@Injectable({
  providedIn: 'root'
})

export class BrowserStorageService {

  constructor(@Inject(BROWSER_STORAGE) public storage: Storage) { }

  get(key: string) {
    return this.storage.getItem(key);
  }

  set(key: string, value: string) {
    this.storage.setItem(key, value);
  }

  remove(key: string) {
    this.storage.removeItem(key);
  }

  checkToken() {
    const vigencia = Number(this.get('vigenciaToken'));
    const hora = new Date().getTime();

    if ( hora > vigencia) {
      console.log('Token expirado.');
      this.remove('token');
      this.remove('vigenciaToken');
    }else {
      console.log(hora, ' Token vigente.');
    }
  }
}
