import { Component, Input, Signal, inject } from '@angular/core';
import { SharedPropiedadesService } from '../../services/shared-propiedades.service';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '../../pipes/truncate.pipe';

@Component({
  selector: 'dashboard-borrar-filtros',
  standalone: true,
  imports: [CommonModule, TruncatePipe],
  templateUrl: './dashboard-borrar-filtros.component.html'
})
export class DashboardBorrarFiltrosComponent {
  public propiedadesService = inject(SharedPropiedadesService);
  @Input({required: true}) dashboardReq!: Signal<string>;

  public borrarFiltroBusqueda() {
    this.propiedadesService.busqueda.set('');
    this.propiedadesService.pagina.set(0);
    this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq());
  }

  public borrarFiltroEstado() {
    this.propiedadesService.busquedaEstado.set('');
    this.propiedadesService.pagina.set(0);
    this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq());
  }

  public borrarFiltroCiudad() {
    this.propiedadesService.busquedaCiudad.set('');
    this.propiedadesService.pagina.set(0);
    this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq());
  }

  public borrarFiltroFecha() {
    this.propiedadesService.fechaInicio.set('0000-01-01T00:00:00');
    this.propiedadesService.fechaFin.set('');
    this.propiedadesService.pagina.set(0);
    this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq());
  }

  public borrarFiltroEstatus() {
    this.propiedadesService.estatus.set('');
    this.propiedadesService.pagina.set(0);
    this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq());
  }

  public borrarTodosFiltros() {
    this.propiedadesService.busqueda.set('');
    this.propiedadesService.busquedaEstado.set('');
    this.propiedadesService.busquedaCiudad.set('');
    this.propiedadesService.fechaInicio.set('0000-01-01T00:00:00');
    this.propiedadesService.fechaFin.set('');
    this.propiedadesService.estatus.set('');
    this.propiedadesService.pagina.set(0);
    this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq());
  }
}
