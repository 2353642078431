import { Component, Input, Signal, inject } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { SharedPropiedadesService } from '../../services/shared-propiedades.service';

@Component({
  selector: 'dashboard-fecha-filter',
  standalone: true,
  imports: [NgbDatepickerModule, FormsModule],
  templateUrl: './dashboard-fecha-filter.component.html',
  styleUrl: './dashboard-fecha-filter.component.scss'
})
export class DashboardFechaFilterComponent {
  public calendar = inject(NgbCalendar);
	public formatter = inject(NgbDateParserFormatter);
  public propiedadesService = inject(SharedPropiedadesService);

  @Input({required: true}) dashboardReq!: Signal<string>;

	hoveredDate: NgbDate | null = null;
	fromDate: NgbDate | null = this.calendar.getToday();
	toDate: NgbDate | null = null;

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
      this.propiedadesService.fechaInicio.set(new Date(`${date.month}/${date.day}/${date.year}`).toJSON().slice(0, 10).concat("T00:00:00"));
      this.propiedadesService.pagina.set(0);
      this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq());
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
      this.propiedadesService.fechaFin.set(new Date(`${date.month}/${date.day}/${date.year}`).toJSON().slice(0, 10).concat("T23:59:00"));
      this.propiedadesService.pagina.set(0);
      this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq());
		} else {
			this.toDate = null;
			this.fromDate = date;
      this.propiedadesService.fechaFin.set(new Date(`${date.month}/${date.day}/${date.year}`).toJSON().slice(0, 10).concat("T23:59:00"));
      this.propiedadesService.fechaInicio.set(new Date(`${date.month}/${date.day}/${date.year}`).toJSON().slice(0, 10).concat("T00:00:00"));
      this.propiedadesService.pagina.set(0);
      this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq());
		}
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}
}
