@if (!isLoading) {
  <select class="form-select rounded-3 text-content-tertiary w-50 mx-auto mb-4" id="selectOrdenComentarios"
    (change)="ordenarBitacora($event)"
  >
    <option value="ascendente" selected>Ordenar por: Más antiguo</option>
    <option value="descendente">Ordenar por: Más reciente</option>
  </select>

  <ul class="list-unstyled" *ngIf="bitacora.length > 0">
    @for (entrada of bitacora; track entrada.id) {
     <li class="p-3 bg-surface-secondary fw-semibold text-content-secondary border-bottom border-stroke-secondary">
      {{entrada.fechaAlta | date:'longDate'}} - {{ entrada.mensaje }}
    </li>
    }
    </ul>

    <p
      class="p-3 bg-surface-secondary fw-semibold text-content-secondary border-bottom border-stroke-secondary"
      *ngIf="bitacora.length == 0">
      No hay registros en la bitácora.
    </p>
} @else {
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-oxxo-yellow" role="status">
      <span class="visually-hidden">Cargando...</span>
    </div>
  </div>
}
