<div class="container-fluid"
*ngIf="
  this.propiedadesService.busqueda() ||
  this.propiedadesService.busquedaEstado() ||
  this.propiedadesService.busquedaCiudad() ||
  this.propiedadesService.estatus() ||
  this.propiedadesService.fechaInicio() != '0000-01-01T00:00:00'
">
  <hr>
  <p>Filtros aplicados</p>
  <div class="row row-cols-auto">
    @if (this.propiedadesService.busqueda()) {
    <div class="col mb-2">
      <button type="button" class="btn btn-surface-tertiary rounded-5 text-content-secondary fw-semibold"
        (click)="this.borrarFiltroBusqueda()">
        Búsqueda: {{ this.propiedadesService.busqueda() | truncate: 10 }}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.6405 2.64025C2.89553 2.38522 3.30903 2.38522 3.56406 2.64025L8.00024 7.07643L12.4364 2.64025C12.6915 2.38522 13.1049 2.38522 13.36 2.64025C13.615 2.89529 13.615 3.30878 13.36 3.56382L8.92381 8L13.36 12.4362C13.615 12.6912 13.615 13.1047 13.36 13.3597C13.1049 13.6148 12.6915 13.6148 12.4364 13.3597L8.00024 8.92356L3.56406 13.3597C3.30903 13.6148 2.89553 13.6148 2.6405 13.3597C2.38546 13.1047 2.38546 12.6912 2.6405 12.4362L7.07667 8L2.6405 3.56382C2.38546 3.30878 2.38546 2.89529 2.6405 2.64025Z" />
        </svg>
      </button>
    </div>
    }
    @if (this.propiedadesService.busquedaEstado()) {
    <div class="col mb-2">
      <button type="button" class="btn btn-surface-tertiary rounded-5 text-content-secondary fw-semibold"
        (click)="borrarFiltroEstado()">
        Estado: {{ this.propiedadesService.busquedaEstado() | truncate: 10 }}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.6405 2.64025C2.89553 2.38522 3.30903 2.38522 3.56406 2.64025L8.00024 7.07643L12.4364 2.64025C12.6915 2.38522 13.1049 2.38522 13.36 2.64025C13.615 2.89529 13.615 3.30878 13.36 3.56382L8.92381 8L13.36 12.4362C13.615 12.6912 13.615 13.1047 13.36 13.3597C13.1049 13.6148 12.6915 13.6148 12.4364 13.3597L8.00024 8.92356L3.56406 13.3597C3.30903 13.6148 2.89553 13.6148 2.6405 13.3597C2.38546 13.1047 2.38546 12.6912 2.6405 12.4362L7.07667 8L2.6405 3.56382C2.38546 3.30878 2.38546 2.89529 2.6405 2.64025Z"
            fill="#304158" />
        </svg>
      </button>
    </div>
    }
    @if(this.propiedadesService.busquedaCiudad()) {
    <div class="col mb-2">
      <button type="button" class="btn btn-surface-tertiary rounded-5 text-content-secondary fw-semibold"
        (click)="borrarFiltroCiudad()">
        Municipio: {{ this.propiedadesService.busquedaCiudad() | truncate: 10 }}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.6405 2.64025C2.89553 2.38522 3.30903 2.38522 3.56406 2.64025L8.00024 7.07643L12.4364 2.64025C12.6915 2.38522 13.1049 2.38522 13.36 2.64025C13.615 2.89529 13.615 3.30878 13.36 3.56382L8.92381 8L13.36 12.4362C13.615 12.6912 13.615 13.1047 13.36 13.3597C13.1049 13.6148 12.6915 13.6148 12.4364 13.3597L8.00024 8.92356L3.56406 13.3597C3.30903 13.6148 2.89553 13.6148 2.6405 13.3597C2.38546 13.1047 2.38546 12.6912 2.6405 12.4362L7.07667 8L2.6405 3.56382C2.38546 3.30878 2.38546 2.89529 2.6405 2.64025Z"
            fill="#304158" />
        </svg>
      </button>
    </div>
    }
    @if(this.propiedadesService.fechaInicio() != "0000-01-01T00:00:00") {
    <div class="col mb-2">
      <button type="button" class="btn btn-surface-tertiary rounded-5 text-content-secondary fw-semibold"
        (click)="borrarFiltroFecha()">
        Fecha: {{ this.propiedadesService.fechaInicio() | date: 'shortDate' }} - {{ this.propiedadesService.fechaFin() |
        date: 'shortDate' }}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.6405 2.64025C2.89553 2.38522 3.30903 2.38522 3.56406 2.64025L8.00024 7.07643L12.4364 2.64025C12.6915 2.38522 13.1049 2.38522 13.36 2.64025C13.615 2.89529 13.615 3.30878 13.36 3.56382L8.92381 8L13.36 12.4362C13.615 12.6912 13.615 13.1047 13.36 13.3597C13.1049 13.6148 12.6915 13.6148 12.4364 13.3597L8.00024 8.92356L3.56406 13.3597C3.30903 13.6148 2.89553 13.6148 2.6405 13.3597C2.38546 13.1047 2.38546 12.6912 2.6405 12.4362L7.07667 8L2.6405 3.56382C2.38546 3.30878 2.38546 2.89529 2.6405 2.64025Z"
            fill="#304158" />
        </svg>
      </button>
    </div>
    }
    @if (this.propiedadesService.estatus()) {
    <div class="col mb-2">
      <button type="button" class="btn btn-surface-tertiary rounded-5 text-content-secondary fw-semibold"
        (click)="borrarFiltroEstatus()">
        Estatus: {{ this.propiedadesService.estatus() | slice: 9 | truncate: 10 | titlecase }}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.6405 2.64025C2.89553 2.38522 3.30903 2.38522 3.56406 2.64025L8.00024 7.07643L12.4364 2.64025C12.6915 2.38522 13.1049 2.38522 13.36 2.64025C13.615 2.89529 13.615 3.30878 13.36 3.56382L8.92381 8L13.36 12.4362C13.615 12.6912 13.615 13.1047 13.36 13.3597C13.1049 13.6148 12.6915 13.6148 12.4364 13.3597L8.00024 8.92356L3.56406 13.3597C3.30903 13.6148 2.89553 13.6148 2.6405 13.3597C2.38546 13.1047 2.38546 12.6912 2.6405 12.4362L7.07667 8L2.6405 3.56382C2.38546 3.30878 2.38546 2.89529 2.6405 2.64025Z"
            fill="#304158" />
        </svg>
      </button>
    </div>
    }
  </div>
  <div class="d-flex justify-content-end">
    <button type="button" class="btn btn-content-secondary rounded-5 fw-semibold" (click)="borrarTodosFiltros()">Borrar
      filtros</button>
  </div>

  <hr>

</div>
