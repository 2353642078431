import { Injectable, WritableSignal, computed, inject, signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Propiedad, Ordenamiento, Estatus } from '../models/propiedad.model';
import { BrowserStorageService } from './browser-storage.service';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SharedPropiedadesService {
  private http: HttpClient = inject(HttpClient)
  private browserStorageService: BrowserStorageService = inject(BrowserStorageService);

  public propiedades = signal<Propiedad[]>([]);
  public busqueda = signal<string>('');
  public filtro = signal<Ordenamiento>('id');
  public busquedaEstado = signal<string>('');
  public busquedaCiudad = signal<string>('');
  public fechaInicio = signal<string>('0000-01-01T00:00:00');
  public fechaFin = signal<string>('');
  public estatus = signal<string>('');
  public catalogoEstatus: WritableSignal<Estatus[]> = signal<Estatus[]>([]);

  public pagina = signal<number>(0);
  public totalPropiedades = signal<number>(0);
  public totalPaginas = computed<number>(() => Math.ceil(this.totalPropiedades() / 10));
  public isLoading = new ReplaySubject<boolean>(1);

  constructor() {
    this.isLoading.next(false);
  }

  public getPropiedades(dashboardReq: string) {
    const token = this.browserStorageService.get('token');
    let headers;
    if (token !== null) {
      headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    }
    return this.http.get<Propiedad[]>(dashboardReq, { headers: headers, withCredentials: true });
  }

  public getPropiedadesAndUpdate(dashboardReq: string) {
    this.isLoading.next(true);
    this.getPropiedades(dashboardReq)
      .subscribe({
        next: (propiedades: Propiedad[]) => {
          this.propiedades.set(propiedades);
          this.totalPropiedades.set(propiedades[0]?.total || 0);
          this.isLoading.next(false);
        },
        error: (error: Error) => {
          this.propiedades.set([]);
          this.isLoading.next(false);
          throw new Error(error.message);
        }
      });
  }

  public getEstatus(apiUrl: string) {
    const token = this.browserStorageService.get('token');
    let headers;
    if (token !== null) {
      headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    }
    return this.http.get<Estatus[]>(`${apiUrl}/estatus/visualizar`, { headers: headers, withCredentials: true })
      .subscribe({
        next: (estatus: Estatus[]) => {
          this.catalogoEstatus.set(estatus);
        },
        error: (error: Error) => {
          throw new Error(error.message);
        }
      })
  }

  public cambiarEstatusPropiedad(apiUrl: string, propiedadId: Propiedad["id"], nuevoEstatusId: Estatus["id"]) {
    const token = this.browserStorageService.get('token');
    const endpoint = `${apiUrl}/propiedad/cambiarEstatus`;
    const body = {
      estatusID: nuevoEstatusId,
      estatusPropiedadID: propiedadId
    }
    let headers;
    if (token !== null) {
      headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    }
    return this.http.put(endpoint, body, { headers: headers, withCredentials: true });
  }
}
