import { Component, Input, Signal, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPropiedadesService } from '../../services/shared-propiedades.service';
import data from '../../models/estados-municipios.json';
import { TruncatePipe } from '../../pipes/truncate.pipe';

@Component({
  selector: 'dashboard-estado-filter',
  standalone: true,
  imports: [NgbDropdownModule, TruncatePipe, CommonModule],
  templateUrl: './dashboard-estado-filter.component.html',
  styleUrl: './dashboard-estado-filter.component.css'
})
export class DashboardEstadoFilterComponent {
  @Input({required: true}) dashboardReq!: Signal<string>;
  public propiedadesService = inject(SharedPropiedadesService);

  public estadosMunicipios = data;
  public estados: string[] = Object.keys(this.estadosMunicipios);

  public filtrarPorEstado(estado: string) {
    this.propiedadesService.busquedaCiudad.set('');
    this.propiedadesService.pagina.set(0);
    this.propiedadesService.busquedaEstado.set(estado);
    this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq());
  }
}
