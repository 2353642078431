import { Component, Input, Signal, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPropiedadesService } from '../../services/shared-propiedades.service';
import data from '../../models/estados-municipios.json';
import { TruncatePipe } from '../../pipes/truncate.pipe';

@Component({
  selector: 'dashboard-municipio-filter',
  standalone: true,
  imports: [NgbDropdownModule, CommonModule, TruncatePipe],
  templateUrl: './dashboard-municipio-filter.component.html',
  styleUrl: './dashboard-municipio-filter.component.css'
})
export class DashboardMunicipioFilterComponent {
  @Input({required: true}) dashboardReq!: Signal<string>;
  public propiedadesService = inject(SharedPropiedadesService);

  public estadosMunicipios: { [key: string]: string[] } = data;
  public municipios: Signal<string[]> = computed(()=> this.estadosMunicipios[this.propiedadesService.busquedaEstado()]);

  public filtrarPorMunicipio(municipio: string) {
    this.propiedadesService.busquedaCiudad.set(municipio);
    this.propiedadesService.pagina.set(0);
    this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq())
  }
}
