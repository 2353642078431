/*
 * Public API Surface of shared-lib
 */
export * from './lib/components/dashboard-propiedad/dashboard-propiedad.component';
export * from './lib/models/propiedad.model';
export * from './lib/services/shared-propiedades.service';
export * from './lib/components/dashboard-search-box/dashboard-search-box.component';
export * from './lib/components/dashboard-sort-dropdown/dashboard-sort-dropdown.component';
export * from './lib/pipes/truncate.pipe';
export * from './lib/components/dashboard-estado-filter/dashboard-estado-filter.component';
export * from './lib/components/dashboard-municipio-filter/dashboard-municipio-filter.component';
export * from './lib/components/dashboard-fecha-filter/dashboard-fecha-filter.component';
export * from './lib/components/dashboard-estatus-filter/dashboard-estatus-filter.component';
export * from './lib/components/dashboard-paginacion/dashboard-paginacion.component';
export * from './lib/components/dashboard-borrar-filtros/dashboard-borrar-filtros.component';
export * from './lib/components/shared-breadcrumbs/shared-breadcrumbs.component';
export * from './lib/components/form-label/form-label.component';
export * from './lib/models/comentario.model';
export * from './lib/models/foto-propiedad.model';
export * from './lib/components/boton-regresar/boton-regresar.component';
export * from './lib/services/datos-inmueble.service';
export * from './lib/components/shared-comentarios/shared-comentarios.component'
export * from './lib/models/documento.model';
export * from './lib/services/browser-storage.service'
export * from './lib/components/shared-bitacora/shared-bitacora.component';
export * from './lib/models/opciones-alta.model';
