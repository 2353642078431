import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'form-label',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-label.component.html',
  styleUrl: './form-label.component.scss'
})
export class FormLabelComponent {
  @Input({ required: true }) label: string = '';
  @Input({ required: true }) for: string = '';
  @Input({ required: true }) form!: FormGroup;
  @Input({ required: true }) control: string = '';
}
