import { Component, Input, Signal, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPropiedadesService } from '../../services/shared-propiedades.service';
import { Ordenamiento } from '../../models/propiedad.model';

@Component({
  selector: 'dashboard-sort-dropdown',
  standalone: true,
  imports: [NgbDropdownModule, CommonModule],
  templateUrl: './dashboard-sort-dropdown.component.html'
})
export class DashboardSortDropdownComponent {
  public propiedadesService = inject(SharedPropiedadesService);
  @Input({ required: true }) dashboardReq!: Signal<string>;

  public ordenamientosValidos: Ordenamiento[] = ['id', 'nombre', 'ciudad', 'estado', 'fecha', 'estatus'];

  public ordenarPor(ordenamiento: Ordenamiento) {
    this.propiedadesService.filtro.set(ordenamiento);
    this.propiedadesService.pagina.set(0);
    this.propiedadesService.getPropiedadesAndUpdate(this.dashboardReq())
  }
}
