import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, Signal, WritableSignal, computed, inject, signal } from '@angular/core';
import { FotoPropiedad } from '../models/foto-propiedad.model';
import { DatosInmueble } from '../models/datos-inmueble.model';
import { BrowserStorageService } from './browser-storage.service';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { CatalogoOpciones } from '../models/opciones-alta.model';

@Injectable({
  providedIn: 'root'
})

export class DatosInmuebleService {
  private http = inject(HttpClient);
  private browserStorageService = inject(BrowserStorageService);

  public propiedadId: WritableSignal<number> = signal(0);
  public propiedad: WritableSignal<any> = signal({});
  public fotosPropiedad: Signal<FotoPropiedad[]> = signal([]);
  public tipoPersona: WritableSignal<string> = signal('');
  public loading: WritableSignal<boolean> = signal(true);

  // fetch a propiedad con el id propiedadId
  public getDatosInmueble (endpoint: string): Observable<DatosInmueble> {
    this.loading.set(true);
    const token = this.browserStorageService.get('token');
    let headers;
    if (token !== null) {
      headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    }
    return this.http.get<DatosInmueble>(endpoint+this.propiedadId(), { headers: headers, withCredentials: true })
    .pipe(
      tap((propiedad: DatosInmueble) => {
        this.propiedad.set(propiedad);
        this.fotosPropiedad = computed(() => {
          return this.propiedad()?.documentos.filter((doc: { tipo: string; }) => doc.tipo === 'Foto de propiedad');
        });
        if(propiedad.propietarioMoral === null) this.tipoPersona.set('fisica');
        if(propiedad.propietarioFisico === null) this.tipoPersona.set('moral');
        this.loading.set(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this.loading.set(false);
        return throwError(() => new Error(`Error al obtener los datos de la propiedad: ${error.error.message}`));
      })
    );
  }

  public crearComentario(comentario: string, apiUrl: string) {
    const token = this.browserStorageService.get('token');
    let headers;
    if (token !== null) {
      headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    }
    const reqBody = {
      "propiedad": {
        "id": this.propiedadId()
      },
      "mensaje": comentario,
    };
    return this.http.post(`${apiUrl}/comentario/crear`, reqBody, { headers: headers, withCredentials: true });
  }

  public getCatalogoOpciones(apiUrl:string, opcion: string) {
    return this.http.get<CatalogoOpciones[]>(`${apiUrl}/valoresFijos/consultar?tipo=${opcion}`, { withCredentials: true });
  }
}
