<div ngbDropdown container="body" class="d-inline-block w-100">
  <button type="button"
    class="btn btn-outline-stroke-primary text-content-secondary rounded-3 d-flex align-items-center gap-2 w-100"
    id="dropdownEstatus" ngbDropdownToggle>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_10_8280)">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 1.63265C5.37884 1.63265 1.63265 5.37884 1.63265 10C1.63265 14.6212 5.37884 18.3673 10 18.3673C14.6212 18.3673 18.3673 14.6212 18.3673 10C18.3673 5.37884 14.6212 1.63265 10 1.63265ZM8.57143 6.02041C8.57143 5.40048 9.07399 4.89796 9.69388 4.89796C10.3138 4.89796 10.8163 5.40048 10.8163 6.02041C10.8163 6.64034 10.3138 7.14286 9.69388 7.14286C9.07399 7.14286 8.57143 6.64034 8.57143 6.02041ZM7.95918 8.77551C7.95918 8.32467 8.32467 7.95918 8.77551 7.95918H9.38776C9.76634 7.95918 10.1297 8.10947 10.3977 8.37742M7.95918 8.77551C7.95918 9.22636 8.32467 9.59184 8.77551 9.59184L7.95918 8.77551ZM8.77551 9.59184H9.18367H8.77551ZM9.18367 9.59184V12.449C9.18367 12.9901 9.3986 13.5093 9.78138 13.8921C10.1642 14.2749 10.6833 14.4898 11.2245 14.4898H11.8367C12.2876 14.4898 12.6531 14.1243 12.6531 13.6735C12.6531 13.2226 12.2876 12.8571 11.8367 12.8571H11.2245C11.1162 12.8571 11.0123 12.8141 10.9358 12.7376C10.8594 12.6611 10.8163 12.5573 10.8163 12.449V9.38776C10.8163 9.00917 10.6656 8.6454 10.3977 8.37742"
          fill="#596888" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8.77551 9.59184H9.18367L10.3977 8.37742L7.95918 8.77551L8.77551 9.59184Z" fill="#596888" />
      </g>
      <defs>
        <clipPath id="clip0_10_8280">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
    Estatus
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownEstatus" class="dp-options-container w-100 shadow">
    @for (estatus of dropdownEstatus(); track estatus.id){
    <button ngbDropdownItem (click)="filtrarPorEstatus(estatus.nombre)">{{ estatus.nombre | slice: 9 | titlecase }}</button>
    }
  </div>
</div>
