<form class="row row-cols-sm-auto">
  <div class="col-12  w-100">
    <div class="dp-hidden position-absolute">
      <div class="input-group">
        <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'"
          (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden"
          [startDate]="fromDate!" tabindex="-1" />
        <ng-template #t let-date let-focused="focused">
          <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
    <div class="input-group">
      <button
        class="w-100 btn btn-outline-stroke-primary d-flex align-items-center gap-2 text-content-secondary rounded-3"
        (click)="datepicker.toggle()" type="button">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <g clip-path="url(#clip0_10_8297)">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M5.71575 0C6.1666 0 6.53208 0.365482 6.53208 0.816326V2.44898H13.4709V0.816326C13.4709 0.365482 13.8363 0 14.2872 0C14.738 0 15.1035 0.365482 15.1035 0.816326V2.44898H17.9606C19.0878 2.44898 20.0015 3.36268 20.0015 4.4898V17.9592C20.0015 19.0863 19.0878 20 17.9606 20H2.04228C0.915169 20 0.00146484 19.0863 0.00146484 17.9592V4.4898C0.00146484 3.36268 0.915169 2.44898 2.04228 2.44898H4.89942V0.816326C4.89942 0.365482 5.26491 0 5.71575 0ZM4.89942 4.08163H2.04228C1.81686 4.08163 1.63412 4.26437 1.63412 4.4898V7.34694H18.3688V4.4898C18.3688 4.26437 18.1861 4.08163 17.9606 4.08163H15.1035V5.10204C15.1035 5.55289 14.738 5.91837 14.2872 5.91837C13.8363 5.91837 13.4709 5.55289 13.4709 5.10204V4.08163H6.53208V5.10204C6.53208 5.55289 6.1666 5.91837 5.71575 5.91837C5.26491 5.91837 4.89942 5.55289 4.89942 5.10204V4.08163ZM18.3688 8.97959H1.63412V17.9592C1.63412 18.1846 1.81686 18.3673 2.04228 18.3673H17.9606C18.1861 18.3673 18.3688 18.1846 18.3688 17.9592V8.97959Z"
              fill="#596888" />
          </g>
          <defs>
            <clipPath id="clip0_10_8297">
              <rect width="20" height="20" fill="none" />
            </clipPath>
          </defs>
        </svg>
        Fecha
      </button>
    </div>
  </div>
</form>
