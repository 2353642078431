<span
  *ngIf="form.controls[control].invalid && (form.controls[control].dirty || form.controls[control].touched)"
  class=" p-1 badge rounded-pill text-oxxo-red bg-white position-absolute top-0 translate-middle-y">
    Error
</span>

<ng-content></ng-content>

<label [for]="for" class="form-label small d-flex align-items-center gap-1"
  [ngClass]="form.controls[control].invalid && (form.controls[control].dirty || form.controls[control].touched) ? 'text-oxxo-red' : 'text-content-tertiary'">
  <svg
    *ngIf="form.controls[control].invalid && (form.controls[control].dirty || form.controls[control].touched)"
    xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#clip0_395_3525)">
      <path
        d="M6.48993 4.16338C6.48993 3.89287 6.27064 3.67358 6.00013 3.67358C5.72962 3.67358 5.51033 3.89287 5.51033 4.16338V7.83685C5.51033 8.10736 5.72962 8.32665 6.00013 8.32665C6.27064 8.32665 6.48993 8.10736 6.48993 7.83685V4.16338Z"
        fill="#B90F42" />
      <path
        d="M6.00013 8.81644C5.62819 8.81644 5.32666 9.11797 5.32666 9.48991C5.32666 9.86185 5.62819 10.1634 6.00013 10.1634C6.37207 10.1634 6.6736 9.86185 6.6736 9.48991C6.6736 9.11797 6.37207 8.81644 6.00013 8.81644Z"
        fill="#B90F42" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M6 0.000244141C5.7431 0.000244141 5.49135 0.0722372 5.27331 0.20807C5.05531 0.343877 4.87966 0.538085 4.7664 0.76871L0.126637 10.2206C0.034161 10.4089 -0.00897269 10.6178 0.00155248 10.8273C0.0120796 11.0368 0.0758725 11.2404 0.186863 11.4184C0.297853 11.5965 0.452378 11.7434 0.635812 11.8451C0.635812 11.8451 1.01816 12.0001 1.23565 12.0001H10.7644C10.9743 12.0003 11.1807 11.9469 11.3642 11.8451C11.5476 11.7434 11.7021 11.5966 11.8132 11.4184C11.8132 11.4184 12 11.0001 11.9984 10.8273C12.009 10.6177 11.9658 10.4088 11.8734 10.2207L7.2336 0.76871C7.12033 0.538085 6.94468 0.343877 6.72669 0.20807C6.50864 0.0722372 6.25689 0.000244141 6 0.000244141ZM5.79127 1.03952L5.79747 1.03574C5.85863 0.999184 5.92861 0.979836 6 0.979836C6.07381 0.979836 6.14611 1.00052 6.20872 1.03952C6.27135 1.07854 6.32181 1.13438 6.35432 1.20053L7.91397 4.37778L10.9942 10.6527C11.0134 10.6918 11.0223 10.7349 11.0201 10.7781C11.0179 10.8215 11.0047 10.8637 10.9818 10.9003C10.9589 10.9371 10.927 10.9674 10.889 10.9885C10.851 11.0096 10.808 11.0206 10.7647 11.0205H1.23489C1.19156 11.0206 1.14892 11.0095 1.11103 10.9885C1.07307 10.9675 1.04108 10.937 1.01816 10.9002C0.995274 10.8635 0.982088 10.8215 0.97991 10.7781C0.977736 10.7349 0.986735 10.6915 1.00589 10.6525L5.64568 1.20053C5.67818 1.13435 5.72862 1.07855 5.79127 1.03952Z"
        fill="#B90F42" />
    </g>
    <defs>
      <clipPath id="clip0_395_3525">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
  {{ label }}
</label>
